<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=Via%20Tenente%20Lingnola%20Helios,%20corbara,%20Italia&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2><b>Helios Formazione</b>: Il tuo learning provider</h2>
                    <p>Helios Formazione è una struttura specializzata nella progettazione e realizzazione di soluzioni per il corretto e proficuo utilizzo delle nuove tecnologie come un reale strumento di crescita e sviluppo, per aziende, enti e per chiunque voglia dotarsi dei mezzi più idonei a vivere nella società dell’informazione globale. Siamo una realtà giovane e dinamica, nata da esperienze pluriennali in aziende, enti pubblici ed istituti bancari e divenuta in pochi anni un punto di rifermento nel panorama della formazione informatica e linguistica e nella creazione di servizi Internet. Conosciamo le esigenze delle più svariate tipologie di utenti e lavoriamo con impegno per soddisfarle.</p>
                    <p>
                        In un contesto come quello della società dell’informazione globale, in cui l’informatica, Internet, la multimedialità fanno parte integrante del patrimonio conoscitivo di qualsiasi professionalità e organizzazione, diviene fondamentale il tipo di approccio con cui questi strumenti sono inseriti nelle varie e realtà.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Mission</h2>
                    <p>
                        Helios Formazione è la soluzione ad ogni vostra esigenza nell’apprendimento e nell’uso delle tecnologie informatiche e nelle lingue. Dispone degli strumenti più adatti a gestire al meglio i costanti e continui cambiamenti che l’informatizzazione introduce nella realtà quotidiana di soggetti e organizzazioni.
                    </p>
                    <p>
                        La risposta di Helios Formazione alla sempre maggiore domanda di servizi formativi e Internet based è in grado di arricchire la vostra organizzazione di quanto di meglio l’Information Technology possa offrire. I continui miglioramenti, la costante evoluzione delle tecnologie e delle metodologie di lavoro, in un quadro in cui è spesso difficile individuare chiaramente i confini delle competenze e professionalità, richiedono un partner unico, qualificato e in grado di individuare e fornire le soluzioni più adatte.
                    </p>
                    <p>
                        Per questo Helios Formazione è il vostro interlocutore ideale, capace di offrirvi, chiavi in mano, le soluzioni più sicure, efficenti ed affidabili.
                        Puntiamo ad arricchire il cliente con quanto di più positivo si possa ottenere dalla tecnologie e dalle nuove forme di comunicazione, per massimizzarne la competitività e l’efficienza
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Via Tenente Lingnola 20<br>84010 Corbara (SA)</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:+393511871965">(+39) 351-1871965</a></p>
                            <p>e-mail: <a href="mailto:info@heliosformazione.com">info@heliosformazione.com</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                            <p>Sabato: 09:00 - 13:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row align-items-center">
    <div class="col-lg-1 col-12 col-sm-3 col-md-3"></div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/logo-eirsaf.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/new-form-academy-partner-3_1.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/9000-LOGO.jpg" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/new-form-academy-partner-6_1.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/logo-pekit.png" alt="image">
        </div>
    </div>
    <div class="col-lg-1 col-6 col-sm-3 col-md-3"></div>
</div>

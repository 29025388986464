<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Privacy Policy</li>
            </ul>
            <h2>Privacy Policy</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="privacy-policy-content">
                    <section class="privacy-section">
                        <div class="auto-container">
                            <!-- Privacy Content -->
                            <div class="privacy-content">
                                <h3><span class="text-uppercase">Informativa ai sensi dell'art. 13 del Codice della Privacy</span></h3>
                                <p>Ai sensi dell'articolo 13 del codice della D.Lgs. 196/2003, vi rendiamo le seguenti informazioni.
                                Noi riteniamo che la privacy dei nostri visitatori sia estremamente importante. Questo documento descrive
                                dettagliatamente i tipi di informazioni personali raccolti e registrati dal nostro sito e come essi
                                vengano utilizzati.</p>
                                <h3><span class="text-uppercase">File di Registrazione (Log Files)</span></h3>
                                <p>Come molti altri siti web, il nostro utilizza file di log. Questi file registrano semplicemente i
                                visitatori del sito - di solito una procedura standard delle aziende di hosting e dei servizi di analisi
                                degli hosting. Le informazioni contenute nei file di registro comprendono indirizzi di protocollo
                                Internet (IP), il tipo di browser, Internet Service Provider (ISP), informazioni come data e ora,
                                pagine referral, pagine d'uscita ed entrata o il numero di clic. Queste informazioni vengono utilizzate
                                per analizzare le tendenze, amministrare il sito, monitorare il movimento degli utenti sul sito e
                                raccogliere informazioni demografiche. Gli indirizzi IP e le altre informazioni non sono collegate
                                informazioni personali che possono essere identificate, dunque tutti i dati sono raccolti in forma
                                assolutamente anonima.</p>
                                <p><b>Privacy Policy aggiornata a Febbraio 2022.</b></p>
                           </div>
                        </div>
                </section>
            </div>

        </div>
    </div>
</div>

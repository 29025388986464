<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Cookie Policy</li>
            </ul>
            <h2>Cookie Policy</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="terms-of-service-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="terms-of-service-content">
                    <div class="privacy-content">
                        <h3><span class="text-uppercase">La presente Cookie Policy, riguarda l’utilizzo
                           di Cookie da parte del Sito web </span></h3>
                        <p>I Cookie sono piccoli file di testo di piccole dimensioni che possono
                           essere utilizzati dai siti web per rendere più efficiente l’esperienza
                           per l’Utente e che vengono inviate al suo browser, dove vengono
                           memorizzati per essere poi ritrasmessi allo stesso Sito web alla sua
                           successiva visita del medesimo. I Cookie hanno funzioni diverse.
                           I Cookie hanno il fine di migliorare la funzionalità e la navigazione
                           di questo Sito web. In altri casi, invece, i Cookie sono utilizzati per
                           monitorare gli utenti durante la navigazione, che registrano
                           informazioni su ciò che l’Utente compra o potrebbe voler comprare,
                           per personalizzare la pubblicità che gli viene mostrata quando apre
                           la mail o naviga su un social network e per personalizzare contenuti,
                           fornire le funzioni dei social media e analizzare il traffico.
                           Nel suo browser l’Utente può impostare le preferenze di Privacy in
                           modo da non memorizzare Cookie, cancellarli dopo ogni visita o ogni
                           volta che chiude il browser.</p>
                        <h3><span class="text-uppercase">Cookie Tecnici</span></h3>
                        <p>I Cookie tecnici sono quelli il cui utilizzo non richiede il consenso
                           dell’Utente. I Cookie tecnici sono utilizzati al solo fine di rendere
                           possibile la navigazione dell’Utente sul Sito web e consentirgli di
                           utilizzarne le funzionalità. Si tratta sempre di Cookie di prima parte,
                           in quanto sono veicolati direttamente dal Titolare sul Sito web. I
                           Cookie di navigazione sono normalmente Cookie di sessione e, pertanto,
                           una volta chiuso il browser di navigazione, vengono disattivati
                           automaticamente. Altri Cookie tecnici sono utili per consentire di
                           memorizzare alcune preferenze dell’Utente senza doverle reimpostare
                           nel corso delle visite successive (c.d. Cookie di Funzionalità).
                           Per questo motivo, i Cookie di funzionalità sono spesso Cookie
                           persistenti, in quanto rimangono memorizzati nel computer dell’Utente
                           anche dopo la chiusura del browser di navigazione, fino alla data di
                           scadenza per essi prevista o fino a quando l’Utente abbia deciso
                           di eliminarli.</p>
                        <h3><span class="text-uppercase">Cookie di Terze parti</span></h3>
                        <p>Questi Cookie sono utilizzati al fine di raccogliere informazioni
                           sull’utilizzo del Sito web fatto da parte degli Utenti in forma
                           anonima quali: pagine visitate, tempo di permanenza, origini del
                           traffico di provenienza, provenienza geografica, età, genere e
                           interessi ai fini statistici e di campagne di marketing. Questi Cookie
                           sono inviati da domini di terze parti esterni al Sito web.</p>

                        <h3><span class="text-uppercase">Cookie di profilazione</span></h3>
                        <p>I Cookie di profilazione sono utilizzati per creare un profilo di
                              Utente, basato sulle preferenze ed i gusti da lui manifestati durante
                              la navigazione su Internet, e fargli visualizzare messaggi
                              pubblicitari coerenti con il suo profilo. In questo modo i messaggi
                              pubblicitari che l’Utente visualizzerà su questo Sito web potranno
                              essere per lui maggiormente interessanti. Come previsto dalla
                              normativa vigente in materia di privacy, per l’installazione di
                              tali Cookie è richiesto il preventivo consenso.</p>

                        <h3><span class="text-uppercase">Eliminare o disattivare i Cookie</span></h3>
                        <p>Fatta eccezione per i Cookie tecnici strettamente necessari alla
                              normale navigazione, il conferimento dei Dati è rimesso alla volontà
                              dell’Utente che decide di navigare sul sito dopo aver preso visione
                              dell’informativa breve contenuta nell’apposito banner e di usufruire
                              dei servizi di terze parti che comportano l’installazione di Cookie.
                              L’Utente può quindi evitare l’installazione dei Cookie mantenendo il
                              banner o attraverso le apposite funzioni disponibili sul proprio
                              browser o in questa policy. L’Utente può gestire le preferenze
                              relative ai Cookie direttamente all’interno del suo browser ed
                              impedire che terze parti possano installarne.</p><br>
                        <p>È importante che l’Utente sappia che disabilitando tutti i Cookie,
                              il funzionamento di questo Sito potrebbe essere compromesso.</p>
                        <p>Ciascun browser presenta procedure diverse per la gestione delle impostazioni.</p>
                        <p>L’Utente può ottenere istruzioni specifiche attraverso i link sottostanti.</p><br>
                        <p>Eliminare/disattivare i cookie Android al seguente indirizzo:
                        <a href="https://support.google.com/chrome/answer/2392971?hl=it-IT">https://support.google.com/chrome/answer/2392971?hl=it-IT</a></p>
                        <p>Eliminare/disattivare i cookie iOS al seguente indirizzo:
                        <a href="https://support.apple.com/it-it/HT201265">https://support.apple.com/it-it/HT201265</a></p>
                        <p>Eliminare/disattivare i cookie con Explorer al seguente indirizzo:
                        <a href="http://windows.microsoft.com/it-IT/windows-vista/Delete-your-Internet-cookies">http://windows.microsoft.com/it-IT/windows-vista/Delete-your-Internet-cookies</a></p>
                        <p>Eliminare/disattivare i cookie di Mozilla Firefox al seguente indirizzo:
                        <a href="http://support.mozilla.com/it/kb/Eliminare%20i%20cookie">http://support.mozilla.com/it/kb/Eliminare%20i%20cookie</a>
                        <p>Eliminare/disattivare i cookie con Chrome al seguente indirizzo:
                        <a href="https://support.google.com/chrome/bin/answer.py?hl=it&amp;answer=95647">http://support.google.com/chrome/bin/answer.py?hl=it&amp;answer=95647</a></p>
                        <p>Eliminare/disattivare i cookie con Safari al seguente indirizzo:
                        <a href="http://support.apple.com/kb/PH5042">http://support.apple.com/kb/PH5042</a></p>
                        <p>Eliminare/disattivare i cookie con Opera al seguente indirizzo:
                        <a href="http://www.opera.com/browser/tutorials/security/privacy/">http://www.opera.com/browser/tutorials/security/privacy/</a></p>
                        <p>Per disabilitare i Cookie di terze parti è inoltre possibile utilizzare
                           <a href="http://www.youronlinechoices.com/it/le-tue-scelte ">Your Online Choices</a>,
                              un servizio web gestito dall’associazione non-profit European
                              Interactive Digital Advertising Alliance (EDAA) che fornisce
                              informazioni sulla pubblicità comportamentale basata sui Cookie di
                              profilazione e consente agli utenti di opporsi facilmente (opt-out)
                              alla loro installazione. Eliminando tutti i Cookie dal proprio browser
                              o rimuovendoli attraverso servizi come Your Online Choices questi,
                              se di terza parte, saranno inibiti genericamente, non solo nel
                              perimetro di questo sito.</p>
                        <h3 class="text-center"><span class="text-uppercase">Ulteriori informazioni sul trattamento</span></h3>
                        <p>Su richiesta dell’Utente, in aggiunta alle informazioni contenute in
                              questa Cookie Policy, questo Sito web potrebbe fornire delle Informative
                              aggiuntive e contestuali riguardanti servizi specifici, o la raccolta
                              ed il trattamento di Dati Personali. Per necessità legate al
                              funzionamento ed alla manutenzione, questo Sito e gli eventuali
                              servizi terzi da esso utilizzati potrebbero raccogliere Log di
                              sistema, ossia file che registrano le interazioni e che possono
                              contenere anche Dati Personali. Maggiori informazioni in relazione
                              al trattamento dei Dati Personali potranno essere richieste in
                              qualsiasi momento al Titolare del Trattamento utilizzando le
                              informazioni di contatto.</p>
                        <h3><span class="text-uppercase">Esercizio dei diritti dell’interessato</span></h3>
                        <p>Ai sensi degli art. 15-22 del Regolamento Europeo n. 679/2016,
                              l’Interessato ha diritto di ottenere la conferma dell’esistenza o
                              meno di dati personali che lo riguardano, anche se non ancora
                              registrati, e la loro comunicazione in forma intelligibile.
                              L’interessato ha diritto di ottenere indicazione:</p>
                        <p>a) dell’origine dei dati personali;</p>
                        <p>b) delle finalità e modalità del trattamento;</p>
                        <p>c) della logica applicata in caso di trattamento effettuato con
                              l’ausilio di strumenti elettronici;</p>
                        <p>d) degli estremi identificativi del Titolare e dei Responsabili del
                              Trattamento dei Dati Personali;</p>
                        <p>e) dei soggetti o delle categorie di soggetti ai quali i Dati
                              Personali possono essere comunicati o che possono venirne a conoscenza
                              in qualità di rappresentante designato nel territorio dello Stato,
                              di responsabili o incaricati;</p>
                        <p>f) l’aggiornamento, la rettificazione ovvero, quando vi ha interesse,
                              l’integrazione dei Dati;</p>
                        <p>g) la cancellazione, la trasformazione in forma anonima o il blocco
                              dei dati trattati in violazione di legge, compresi quelli di cui
                              non è necessaria la conservazione in relazione agli scopi per i
                              quali i dati sono stati raccolti o successivamente trattati.</p>
                        <p><b>Il Titolare non è responsabile per l’aggiornamento di tutti i link
                              visualizzabili nella presente Cookie Policy, pertanto ogni qualvolta
                              un link non sia funzionante e/o aggiornato, gli Utenti riconoscono
                              ed accettano che dovranno sempre far riferimento al documento e/o
                              sezione dei siti internet richiamati da tale link.</b></p>
                        <p><b>Cookie Policy aggiornata a Febbraio 2022.</b></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
